import { IColumn } from "@/interfaces/IColumn";
import { WorksheetDto } from "@/core/features/worksheets/worksheet.dto";
import { UserDto } from "@/core/features/users/user.dto";
import { PermissionEnum } from "@altertec_gparn/lib/";
import { ComponentPublicInstance } from "@vue/runtime-core";
import { uniq } from "lodash";

export function getWorksheetColumns(
  component: ComponentPublicInstance,
  requiredColumns: string[],
  me?: UserDto
): IColumn<WorksheetDto>[] {
  return [
    {
      label: "Código",
      name: "code",
      field: "code",
      align: "left",
      required: false,
      sortable: true,
    },
    {
      label: "Expediente",
      name: "project.dossier.description",
      field: (worksheet: WorksheetDto) => worksheet.project?.dossier?.description,
      surtitleField: "project.dossier.code",
      align: "left",
      required: requiredColumns.includes("dossier"),
    },
    {
      label: "Proyecto",
      name: "project.code",
      field: (worksheet: WorksheetDto) => (worksheet.project ? worksheet.project.code : null),
      archivedField: (worksheet: WorksheetDto) => worksheet.isArchived(),
      showArchivedTag: true,
      sortable: true,
      align: "left",
      required: requiredColumns.includes("project"),
    },
    {
      label: "Fecha",
      name: "date",
      field: "date",
      showArchivedTag: true,
      archivedField: (worksheet: WorksheetDto) => worksheet.isArchived(),
      align: "left",
      sortable: true,
      type: "date",
      required: requiredColumns.includes("date"),
    },
    {
      label: "Autor",
      name: "author.username",
      field: (worksheet: WorksheetDto) => worksheet.author,
      sortable: true,
      align: "left",
      type: "user",
      required: requiredColumns.includes("author"),
    },
    {
      label: "Técnicos",
      name: "worksheetHasUsers.user.username",
      field: (worksheet: WorksheetDto) =>
        worksheet.worksheetHasUsers?.map((worksheetHasUser) => worksheetHasUser.user) || [],
      align: "left",
      type: "users",
      required: requiredColumns.includes("technicians"),
    },
    {
      label: "Jefe de equipo",
      name: "worksheetHasUsers.isTeamLeader",
      field: (worksheet: WorksheetDto) =>
        worksheet.worksheetHasUsers
          ?.filter((worksheetHasUser) => worksheetHasUser.isTeamLeader)
          .map((worksheetHasUser) => worksheetHasUser.user) || [],
      align: "left",
      type: "users",
      required: requiredColumns.includes("teamLeader"),
      sortable: true,
    },
    {
      label: "Centro",
      name: "project.workplace.name",
      field: (worksheet: WorksheetDto) =>
        worksheet.project && worksheet.project.workplace ? worksheet.project.workplace.name : null,
      sortable: true,
      align: "left",
      required: requiredColumns.includes("workplace"),
    },
    {
      label: "Unidad Operativa",
      name: "project.operativeUnit.code",
      field: (worksheet: WorksheetDto) =>
        worksheet.project && worksheet.project.operativeUnit ? worksheet.project.operativeUnit.toString() : null,
      sortable: true,
      align: "left",
      required: requiredColumns.includes("operativeUnit"),
    },
    {
      label: "Grupo de clientes",
      name: "project.dossier.clientGroup.name",
      field: (worksheet: WorksheetDto) => worksheet.project?.dossier?.clientGroup?.name,
      sortable: true,
      align: "left",
      required: requiredColumns.includes("clientGroup"),
      classes: "auto-width",
      style: "--column-number:7",
    },
    {
      label: "Activos",
      name: "tasks.asset",
      field: (worksheet: WorksheetDto) =>
        worksheet.tasks
          .filter((task) => task.assetId)
          .map((task) => task.asset?.toString())
          .join(", "),
      style: "--column-number:3",
      align: "left",
      required: requiredColumns.includes("tasks.asset"),
    },
    {
      label: "Estado",
      name: "status",
      field: "status",
      sortable: true,
      align: "left",
      type: "worksheet-status",
      required: requiredColumns.includes("status"),
    },
    {
      label: "En el extranjero",
      name: "isAbroad",
      field: "isAbroad",
      sortable: true,
      align: "left",
      type: "boolean",
      required: requiredColumns.includes("isAbroad"),
    },
    {
      label: "Certificación",
      name: "certification.status",
      field: (worksheet: WorksheetDto) => worksheet.certification?.status,
      align: "left",
      type: "certification-status",
      required: requiredColumns.includes("certificationWithStatus"),
      overrideStatusLabel: "certification.code",
      routeFn: (worksheet: WorksheetDto) =>
        component.$securityManager.hasPermission([PermissionEnum.CERTIFICATIONS_PROJECTS], me) &&
        worksheet.certificationId
          ? { name: "Certification Detail", params: { id: worksheet.certificationId } }
          : null,
    },
    {
      label: "Certificación",
      name: "certification.code",
      field: (worksheet: WorksheetDto) => worksheet.certification?.code,
      sortable: true,
      align: "left",
      routeFn: (worksheet: WorksheetDto) =>
        component.$securityManager.hasPermission([PermissionEnum.CERTIFICATIONS_PROJECTS], me) &&
        worksheet.certificationId
          ? { name: "Certification Detail", params: { id: worksheet.certificationId } }
          : null,
      required: requiredColumns.includes("certification"),
    },
    {
      label: "Actualizado en",
      name: "updatedAt",
      field: (worksheet: WorksheetDto) => worksheet,
      align: "left",
      sortable: true,
      required: requiredColumns.includes("updatedAt"),
      type: "updated",
    },
    {
      label: "Comentarios",
      name: "topic.comments",
      field: (worksheet: WorksheetDto) => (worksheet.topic?.comments.length ? worksheet.topic.comments.length : null),
      required: requiredColumns.includes("comments"),
      align: "left",
      type: "comments",
    },
    {
      label: "Tareas",
      name: "tasks",
      field: (worksheet: WorksheetDto) => {
        const taskTypes = worksheet.tasks?.map((task) => task.taskType?.name);
        return taskTypes && taskTypes.length > 0 ? uniq(taskTypes).join(", ") : "";
      },
      align: "left",
      required: requiredColumns.includes("tasks"),
      classes: "auto-width",
      noSearchable: true,
      style: "--column-number:7",
    },
    {
      label: "",
      name: "filesCount",
      field: (worksheet: WorksheetDto) => (worksheet.filesCount > 0 ? "paperclip" : null),
      tooltipText: (worksheet: WorksheetDto) => {
        return worksheet.filesCount
          ? component.$tc("domain.worksheet.tasksAttachments", worksheet.filesCount, { num: worksheet.filesCount })
          : null;
      },
      align: "right",
      required: true,
      type: "icon",
    },
  ];
}
