import { Type } from "class-transformer";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { WarehouseDto } from "@/core/features/warehouses/warehouse.dto";
import { UserDto } from "@/core/features/users/user.dto";

export class UserHasFavoriteWarehouseDto extends BaseEntityDto {
  @Type(() => WarehouseDto)
  warehouse?: WarehouseDto;
  warehouseId: string;

  @Type(() => UserDto)
  user?: UserDto;
  userId: string;
}
