import { createStore, ModuleTree } from "vuex";
import { calendarModule } from "@/modules/calendar/store/calendar.module";
import { IRootState } from "@/store/interfaces/IRootState";
import { ICommit } from "@/store/interfaces/ICommit";
import { schedulerModule } from "@/modules/scheduler/store/scheduler.module";
import { UserDto } from "@/core/features/users/user.dto";
import { CollapseStatusEnum, CurrencyEnum, LangEnum } from "@altertec_gparn/lib";
import { supervisionWorksheetModule } from "@/modules/worksheets/store/supervision-worksheet.module";
import { ICollapsableStorePayload } from "@/interfaces/ICollapsableStorePayload";
import { OperativeUnitDto } from "@/core/features/operative-units/operative-unit.dto";

export default createStore<IRootState>({
  state: {
    navbarItemActive: "",
    navbarVisible: true,
    me: null,
    operativeUnits: [],
    refresh: null,
    lang: LangEnum.ES,
    currency: CurrencyEnum.EUR,
    loading: false,
    expand: {},
    error: null,
    economicDataVisible: null,
  } as IRootState,
  mutations: {
    updateNavbarItem(state: IRootState, value: string) {
      state.navbarItemActive = value;
    },
    toggleNavbar(state: IRootState) {
      state.navbarVisible = !state.navbarVisible;
    },
    updateNavbarState(state: IRootState, value: boolean) {
      state.navbarVisible = value;
    },
    saveMe(state: IRootState, value: UserDto) {
      state.me = value;
    },
    saveOperativeUnits(state: IRootState, value: OperativeUnitDto[]) {
      state.operativeUnits = value;
    },
    saveError(state: IRootState, error: Error) {
      state.error = error;
    },
    cleanError(state: IRootState) {
      state.error = null;
    },
    reset(state: IRootState) {
      state.me = null;
      state.isScrolled = false;
    },
    refresh(state: IRootState) {
      state.refresh = new Date().getTime();
    },
    expand(state: IRootState, field: string) {
      if (!state.expand[field]) state.expand[field] = {};
      Object.keys(state.expand[field]).forEach((item) => (state.expand[field][item] = CollapseStatusEnum.EXPANDED));
    },
    collapse(state: IRootState, field: string) {
      if (!state.expand[field]) state.expand[field] = {};
      Object.keys(state.expand[field]).forEach((item) => (state.expand[field][item] = CollapseStatusEnum.COLLAPSED));
    },
    switchExpand(state: IRootState, { field, item, value }: ICollapsableStorePayload) {
      if (!state.expand[field]) state.expand[field] = {};
      Object.assign(state.expand[field], {
        [item]: value,
      });
    },
    setLoading(state: IRootState, value: boolean) {
      state.loading = value;
    },
    setEconomicDataVisible(state: IRootState, id: string) {
      state.economicDataVisible = id;
    },
  },
  actions: {
    updateNavbarItem({ commit }: ICommit, value: string) {
      commit("updateNavbarItem", value);
    },
    toggleNavbar({ commit }: ICommit) {
      commit("toggleNavbar");
    },
    updateNavbarState({ commit }: ICommit, value: boolean) {
      commit("updateNavbarState", value);
    },
    saveMe({ commit }: ICommit, user) {
      commit("saveMe", user);
    },
    saveOperativeUnits({ commit }: ICommit, operativeUnits) {
      commit("saveOperativeUnits", operativeUnits);
    },
    saveError({ commit }: ICommit, error) {
      commit("saveError", error);
    },
    cleanError({ commit }: ICommit) {
      commit("cleanError");
    },
    reset({ commit }: ICommit) {
      commit("reset");
    },
    refresh({ commit }: ICommit) {
      commit("refresh");
    },
    expandAll({ commit }: ICommit, field) {
      commit("expand", field);
    },
    collapseAll({ commit }: ICommit, field) {
      commit("collapse", field);
    },
    switchExpand({ commit }: ICommit, payload: ICollapsableStorePayload) {
      commit("switchExpand", payload);
    },
    setLoading({ commit }: ICommit, value) {
      commit("setLoading", value);
    },
    setEconomicDataVisible({ commit }: ICommit, id: string) {
      commit("setEconomicDataVisible", id);
    },
  },
  modules: {
    "my-calendar": calendarModule,
    "calendar-report": calendarModule,
    "times-off-calendar": calendarModule,
    schedulerModule: schedulerModule,
    supervisionWorksheetModule: supervisionWorksheetModule,
  } as ModuleTree<IRootState>,
});
