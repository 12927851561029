import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { CreateTimeOffDto } from "@/core/features/times-off/create-time-off.dto";
import { UpdateTimeOffDto } from "@/core/features/times-off/update-time-off.dto";
import { HttpRequest } from "@/core/common/http/http-request";
import { FindManyResult } from "@/core/common/resource-base/find-many-result.dto";
import { TimeOffDto } from "@/core/features/times-off/time-off.dto";
import { IRequest } from "@/interfaces/IRequest";
import { CondOperator } from "@nestjsx/crud-request";
import { UserDto } from "@/core/features/users/user.dto";
import { plainToInstance } from "class-transformer";
import { SuperviseTimeOffDto } from "@/core/features/times-off/supervise-time-off.dto";
import { TimeOffRecordDto } from "@/core/features/times-off/time-off-record.dto";
import { TimeOffReportDto } from "@/core/features/times-off/time-off-report.dto";
import { ValidateTimeOffDto } from "@/core/features/times-off/validate-time-off.dto";
import { cloneDeep } from "lodash";
import { getToday } from "@/utils/dates/dateUtils";
import { formatApiDate } from "@/utils/dates/dateFormats";
import { endOfYear, startOfYear } from "date-fns";

export class TimesOffResource extends ResourceBaseService<TimeOffDto, CreateTimeOffDto, UpdateTimeOffDto> {
  protected EntityDto = TimeOffDto;
  protected CreateEntityDto = CreateTimeOffDto;
  protected UpdateEntityDto = UpdateTimeOffDto;
  protected RecordEntityDto = TimeOffRecordDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/times-off");
  }

  async getMyTimesOff(userId: string, request: IRequest = {}): Promise<FindManyResult<TimeOffDto>> {
    const req = cloneDeep(request);
    if (!req.filters) req.filters = {};
    req.filters["timeOffHasUsers.userId"] = userId;
    return this.getMine(req);
  }

  setApproved(id: string, approveTimeOff: SuperviseTimeOffDto): Promise<TimeOffDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/set-approved",
        method: "PATCH",
        auth: true,
        body: plainToInstance(SuperviseTimeOffDto, approveTimeOff),
      })
    );
  }

  setValidated(id: string, timeOffTypeId?: string): Promise<TimeOffDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/set-validated",
        method: "PATCH",
        auth: true,
        body: plainToInstance(ValidateTimeOffDto, { timeOffTypeId: timeOffTypeId, timeOffId: id }),
      })
    );
  }

  setRejected(id: string, rejectTimeOff: SuperviseTimeOffDto): Promise<TimeOffDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/set-rejected",
        method: "PATCH",
        auth: true,
        body: plainToInstance(SuperviseTimeOffDto, rejectTimeOff),
      })
    );
  }

  getTimeOffReport(request: IRequest): Promise<FindManyResult<TimeOffReportDto>> {
    return this.apiHttpClient
      .request<TimeOffReportDto, FindManyResult<TimeOffReportDto>>(
        HttpRequest.create({
          url: this.resourceUrl + "/report",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((findManyResult) => {
        findManyResult.data = plainToInstance(TimeOffReportDto, findManyResult.data);
        return findManyResult;
      });
  }

  getTotalsFromTimeOffReport(request: IRequest): Promise<{ days: number }> {
    return this.apiHttpClient.request<{ days: number }, { days: number }>(
      HttpRequest.create({
        url: this.resourceUrl + "/report-totals",
        method: "GET",
        auth: true,
        query: this.getQueryBuilder(request).query(),
      })
    );
  }

  getAvailableUsers(timeOffId: string, request: IRequest): Promise<UserDto[]> {
    const req = cloneDeep(request);

    req.searchBy = ["id", "username", "firstName", "lastName"];
    req.sortBy = "username";
    req.customAndFilters = [
      {
        field: "collectiveAgreementId",
        operator: CondOperator.NOT_NULL,
        value: "true",
      },
    ];

    return this.apiHttpClient
      .request<UserDto, UserDto[]>(
        HttpRequest.create({
          url: this.resourceUrl + "/" + timeOffId + "/available-users",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(req).query(),
        })
      )
      .then((users) => {
        return plainToInstance(UserDto, users);
      });
  }

  async getMyRejectedCount(): Promise<number> {
    const today = getToday();
    const request: IRequest = {
      filters: {
        from: formatApiDate(startOfYear(today)),
        to: formatApiDate(endOfYear(today)),
        startDateField: "start",
        endDateField: "end",
      },
    };
    return this.apiHttpClient
      .request<null, number>(
        HttpRequest.create({
          url: this.resourceUrl + "/count/rejected",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((result) => Number(result));
  }

  async getSupervisionCount(request: IRequest = {}): Promise<number> {
    return this.apiHttpClient
      .request<null, number>(
        HttpRequest.create({
          url: this.resourceUrl + "/count/supervision",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((result) => Number(result));
  }
}
