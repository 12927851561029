import { Expose, Type } from "class-transformer";
import { CreateFileDto } from "@/core/features/file/create-file.dto";

export class CreateAnswerDto {
  @Expose()
  questionId?: string;

  @Expose()
  equipmentId?: string;

  @Expose()
  documentationId?: string;

  @Expose()
  isMissing?: boolean;

  @Expose()
  selectedAnswerId?: string;

  @Expose()
  freeAnswer?: string;

  @Expose()
  vehicleHasChecklistId: string;

  @Expose()
  @Type(() => CreateFileDto)
  files: CreateFileDto[];
}
