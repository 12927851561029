export enum CopyWorksheetErrorEnum {
    USERS = 'USERS',
    USERS_META = 'USERS_META',
    TASKS = 'TASKS',
    VEHICLES = 'VEHICLES',
}

export const getCopyWorksheetErrorLabel = (copyWorksheetErrorEnum: CopyWorksheetErrorEnum): string => {
    switch (copyWorksheetErrorEnum) {

        case CopyWorksheetErrorEnum.USERS:
        case CopyWorksheetErrorEnum.USERS_META:
            return 'los usuarios';
        case CopyWorksheetErrorEnum.TASKS:
            return 'las tareas';
            case CopyWorksheetErrorEnum.VEHICLES:
            return 'los vehículos';
        default:
            return 'el parte';
    }
};
