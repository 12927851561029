import { Expose } from "class-transformer";
import { TransformToEntityReference } from "@/core/common/resource-base/transform-to-entity-reference";

export class UpdateReferenceDocumentDto {
  @Expose()
  documentationTypeId: string;

  @Expose({ name: "modelIds" })
  @TransformToEntityReference()
  models: string[];

  @Expose({ name: "elementIds" })
  @TransformToEntityReference()
  elements: string[];

  @Expose({ name: "interventionIds" })
  @TransformToEntityReference()
  interventions: string[];

  @Expose({ name: "clientGroupIds" })
  @TransformToEntityReference()
  clientGroups: string[];

  @Expose({ name: "workplaceIds" })
  @TransformToEntityReference()
  workplaces: string[];
}
