import { Expose, Transform, Type } from "class-transformer";
import { toCents } from "@/utils/numberUtils";

export class UpdateItemModelDto {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  brandId: string;

  @Expose()
  externalReference?: string;

  @Expose()
  categoryId: string;

  @Expose()
  subcategoryId?: string;

  @Expose()
  accountingAccountId: string;

  @Expose()
  @Transform(({ value }) => toCents(value))
  usageCost: number;

  @Expose()
  @Type(() => Number)
  lifespan: number;

  @Expose()
  range?: string;
}
