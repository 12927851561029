import { Expose, Transform } from "class-transformer";
import { parseAppDate } from "@/utils/dates/dateFormats";
import { toCents } from "@/utils/numberUtils";

export class UpdateItemDto {
  @Expose()
  itemModelId: string;

  @Expose()
  serialNumber: string;

  @Expose()
  code: string;

  @Expose()
  companyId: string;

  @Expose()
  @Transform(({ value }) => parseAppDate(value, false))
  purchaseDate: string;

  @Expose()
  @Transform(({ value }) => parseAppDate(value, false))
  amortizationStartDate: string;

  @Expose()
  @Transform(({ value }) => toCents(value))
  price: number;

  @Expose()
  warehouseId: string;

  @Expose()
  operativeUnitId: string;

  @Expose()
  locationId: string;

  @Expose()
  vehicleId: string;

  @Expose()
  @Transform(({ value }) => (value ? parseAppDate(value, false) : null))
  nextReviewDate?: Date;

  @Expose()
  certificateNumber?: string;

  @Expose()
  notes?: string;

  @Expose()
  appearsInChecklist: boolean;

  @Expose()
  generatesIssuesInChecklist: boolean;
}
