import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { Transform, Type } from "class-transformer";
import { toEuros } from "@/utils/numberUtils";
import { CategoryDto } from "@/core/features/categories/category.dto";
import { SubcategoryDto } from "@/core/features/subcategories/subcategory.dto";
import { AccountingAccountDto } from "@/core/features/accounting-accounts/accounting-account.dto";
import { BrandDto } from "@/core/features/brands/brand.dto";

export class ItemModelDto extends BaseEntityDto {
  name: string;

  @Type(() => BrandDto)
  brand: BrandDto;
  brandId: string;

  externalReference?: string;

  @Type(() => CategoryDto)
  category: CategoryDto;
  categoryId: string;

  @Type(() => SubcategoryDto)
  subcategory?: SubcategoryDto;
  subcategoryId?: string;

  @Type(() => AccountingAccountDto)
  accountingAccount: AccountingAccountDto;
  accountingAccountId: string;

  @Transform(({ value }) => toEuros(value))
  usageCost: number;

  lifespan: number;

  range?: string;

  qrCode?: string;

  toString(): string {
    return this.name;
  }

  get lifespanInDays() {
    return this.lifespan * 30;
  }
}
