import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { UpdateAnswerDto } from "@/core/features/answers/update-answer.dto";
import { AnswerDto } from "@/core/features/answers/answer.dto";
import { CreateAnswerDto } from "@/core/features/answers/create-answer.dto";
import { CreateFileDto } from "@/core/features/file/create-file.dto";
import { HttpRequest } from "@/core/common/http/http-request";

export class AnswersResource extends ResourceBaseService<AnswerDto, CreateAnswerDto, UpdateAnswerDto> {
  protected EntityDto = AnswerDto;
  protected CreateEntityDto = CreateAnswerDto;
  protected UpdateEntityDto = UpdateAnswerDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/answers");
  }

  createManyFiles(id: string, files: CreateFileDto[]): Promise<AnswerDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/files",
        method: "POST",
        auth: true,
        body: { bulk: files },
      })
    );
  }
}
