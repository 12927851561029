import { RouteRecordRaw } from "vue-router";
import { PermissionEnum } from "@altertec_gparn/lib";

export default {
  path: "/configuration",
  name: "Configuration",
  meta: { sectionAccess: "CONFIGURATION", redirects: true },
  component: () => import("@/layouts/EmptyLayout.vue"),
  children: [
    {
      path: "organizations",
      name: "Organizations Configuration",
      redirect: { name: "Companies" },
      meta: { permissions: [PermissionEnum.CONFIGURATION_ORGANIZATION] },
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          path: "companies/:type?/:id?",
          name: "Companies",
          component: () => import("@/views/auth/configuration/Companies.vue"),
          props: true,
        },
        {
          path: "business-lines/:type?/:id?",
          name: "Business Lines",
          component: () => import("@/views/auth/configuration/BusinessLines.vue"),
          props: true,
        },
        {
          path: "project-phases/:type?/:id?",
          name: "Project Phases",
          component: () => import("@/views/auth/configuration/ProjectPhases.vue"),
          props: true,
        },
        {
          path: "operative-units/:type?/:id?",
          name: "Operative Units",
          component: () => import("@/views/auth/configuration/operative-units/OperativeUnits.vue"),
          props: true,
        },
        {
          path: "interventions/:type?/:id?",
          name: "Interventions",
          component: () => import("@/views/auth/configuration/Interventions.vue"),
          props: true,
        },
      ],
    },
    {
      name: "Accounting Configuration",
      path: "accounting",
      redirect: { name: "Accounting Accounts" },
      meta: { permissions: [PermissionEnum.CONFIGURATION_ACCOUNTING] },
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          path: "accounting-accounts/:type?/:id?",
          name: "Accounting Accounts",
          component: () => import("@/views/auth/configuration/AccountingAccounts.vue"),
          props: true,
        },
        {
          path: "suppliers/:type?/:id?",
          name: "Suppliers",
          component: () => import("@/views/auth/configuration/Suppliers.vue"),
          props: true,
        },
        {
          path: "payment-methods/:type?/:id?",
          name: "Payment Methods",
          component: () => import("@/views/auth/configuration/PaymentMethods.vue"),
          props: true,
        },
        {
          path: "expense-types/:type?/:id?",
          name: "Expense Types",
          component: () => import("@/views/auth/configuration/ExpenseTypes.vue"),
          props: true,
        },
      ],
    },
    {
      name: "Commercial Configuration",
      path: "commercial",
      redirect: { name: "Client Groups" },
      meta: { permissions: [PermissionEnum.CONFIGURATION_COMMERCIAL] },
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          path: "client-groups/:type?/:id?",
          name: "Client Groups",
          component: () => import("@/views/auth/configuration/ClientGroups.vue"),
          props: true,
        },
        {
          path: "client-types/:type?/:id?",
          name: "Client Types",
          component: () => import("@/views/auth/configuration/ClientTypes.vue"),
          props: true,
        },
        {
          path: "clients/:type?/:id?",
          name: "Clients",
          component: () => import("@/views/auth/configuration/Clients.vue"),
          props: true,
        },
        {
          path: "contacts/:type?/:id?",
          name: "Contacts",
          component: () => import("@/views/auth/configuration/Contacts.vue"),
          props: true,
        },
      ],
    },
    {
      path: "access",
      name: "Access Management",
      redirect: { name: "Users" },
      meta: { permissions: [PermissionEnum.CONFIGURATION_ACCESS] },
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          path: "users",
          name: "Users",
          redirect: { name: "User List" },
          component: () => import("@/layouts/EmptyLayout.vue"),
          children: [
            {
              path: "list",
              name: "User List",
              component: () => import("@/views/auth/configuration/users/Users.vue"),
            },
            {
              path: "new",
              name: "User New",
              component: () => import("@/views/auth/configuration/users/UserCreate.vue"),
            },
            {
              path: "edit/:id",
              name: "User Edit",
              component: () => import("@/views/auth/configuration/users/UserEdit.vue"),
              props: true,
            },
          ],
        },
        {
          path: "roles",
          name: "Role Layout",
          redirect: { name: "Role List" },
          component: () => import("@/layouts/EmptyLayout.vue"),
          children: [
            {
              path: "list",
              name: "Role List",
              component: () => import("@/views/auth/configuration/roles/RoleList.vue"),
            },
            {
              path: "new",
              name: "New Role",
              component: () => import("@/views/auth/configuration/roles/RoleCreate.vue"),
            },
            {
              path: "detail/:id",
              name: "Role Detail",
              component: () => import("@/views/auth/configuration/roles/RoleDetail.vue"),
              props: true,
            },
          ],
        },
        {
          path: "api-tokens",
          name: "ApiToken Layout",
          redirect: { name: "ApiToken List" },
          component: () => import("@/layouts/EmptyLayout.vue"),
          children: [
            {
              path: "list",
              name: "ApiToken List",
              component: () => import("@/views/auth/configuration/api-tokens/ApiTokenList.vue"),
            },
            {
              path: "new",
              name: "New ApiToken",
              component: () => import("@/views/auth/configuration/api-tokens/ApiTokenCreate.vue"),
            },
            {
              path: "detail/:id",
              name: "ApiToken Detail",
              component: () => import("@/views/auth/configuration/api-tokens/ApiTokenDetail.vue"),
              props: true,
            },
          ],
        },
      ],
    },
    {
      path: "structures",
      name: "Structures Configuration",
      redirect: { name: "Workplaces" },
      meta: { permissions: [PermissionEnum.CONFIGURATION_STRUCTURE] },
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          path: "workplaces/:type?/:id?",
          name: "Workplaces",
          component: () => import("@/views/auth/configuration/Workplaces.vue"),
          props: true,
        },
        {
          path: "workplace-types/:type?/:id?",
          name: "Workplace Types",
          component: () => import("@/views/auth/configuration/WorkplaceTypes.vue"),
          props: true,
        },
        {
          path: "elevators/:type?/:id?",
          name: "Elevators",
          component: () => import("@/views/auth/configuration/Elevators.vue"),
          props: true,
        },
        {
          path: "assets/:type?",
          name: "Assets",
          redirect: { name: "Assets Page" },
          component: () => import("@/views/auth/configuration/Assets.vue"),
          children: [
            {
              path: ":id?",
              name: "Assets Page",
              components: {
                sidebar: () => import("@/modules/assets/AssetsSidebar.vue"),
              },
              props: true,
            },
          ],
          props: true,
        },
        {
          path: "manufacturers/:type?/:id?",
          name: "Manufacturers",
          component: () => import("@/views/auth/configuration/Manufacturers.vue"),
          props: true,
        },
        {
          path: "models/:type?/:id?",
          name: "Models",
          component: () => import("@/views/auth/configuration/Models.vue"),
          props: true,
        },
        {
          path: "elements/:type?/:id?",
          name: "Elements",
          component: () => import("@/views/auth/configuration/Elements.vue"),
          props: true,
        },
        {
          path: "anti-fall-ppe-types/:type?/:id?",
          name: "AntiFallPpe Types",
          component: () => import("@/views/auth/configuration/AntiFallPpeTypes.vue"),
          props: true,
        },

        {
          path: "zones/:type?/:id?",
          name: "Zones",
          component: () => import("@/views/auth/configuration/Zones.vue"),
          props: true,
        },
      ],
    },
    {
      path: "files",
      name: "Files Configuration",
      redirect: { name: "Documentation Types" },
      meta: { permissions: [PermissionEnum.CONFIGURATION_FILES] },
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          path: "documentation-types/:type?/:id?",
          name: "Documentation Types",
          component: () => import("@/views/auth/configuration/DocumentationTypes.vue"),
          props: true,
        },
      ],
    },
    {
      path: "worksheets",
      name: "Worksheets Configuration",
      redirect: { name: "Task Types" },
      meta: { permissions: [PermissionEnum.CONFIGURATION_WORKSHEETS] },
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          path: "task-types/:type?/:id?",
          name: "Task Types",
          component: () => import("@/views/auth/configuration/TaskTypes.vue"),
          props: true,
        },
        {
          path: "subtasks/:type?/:id?",
          name: "Subtasks",
          component: () => import("@/views/auth/configuration/Subtasks.vue"),
          props: true,
        },
        {
          path: "diet-types/:type?/:id?",
          name: "Diet Types",
          component: () => import("@/views/auth/configuration/DietTypes.vue"),
          props: true,
        },
      ],
    },
  ],
} as RouteRecordRaw;
