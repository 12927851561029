import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { WorksheetDto } from "./worksheet.dto";
import { CreateWorksheetDto } from "./create-worksheet.dto";
import { UpdateWorksheetDto } from "./update-worksheet.dto";
import { HttpRequest } from "../../common/http/http-request";
import { plainToInstance } from "class-transformer";
import { FindManyResult } from "@/core/common/resource-base/find-many-result.dto";
import { IRequest } from "@/interfaces/IRequest";
import { CondOperator } from "@nestjsx/crud-request";
import { SetValidatedDto } from "@/core/features/worksheets/set-validated.dto";
import { WorksheetTaskSummaryDto } from "@/core/features/worksheets/worksheet-task-summary.dto";
import { MoveWorksheetDto } from "@/core/features/worksheets/move-worksheet.dto";
import { WorksheetRecordDto } from "@/core/features/worksheets/worksheet-record.dto";
import { formatApiDate } from "@/utils/dates/dateFormats";
import { CopyWorksheetDto } from "@/core/features/worksheets/copy-worksheet.dto";
import { OptionType } from "@/types/OptionType";
import { getOptionLoader } from "@/core/common/presenters/optionLoader";
import { merge } from "lodash";
import { WorksheetStatusEnum } from "@altertec_gparn/lib";

export class WorksheetsResource extends ResourceBaseService<WorksheetDto, CreateWorksheetDto, UpdateWorksheetDto> {
  protected EntityDto = WorksheetDto;
  protected CreateEntityDto = CreateWorksheetDto;
  protected UpdateEntityDto = UpdateWorksheetDto;
  protected RecordEntityDto = WorksheetRecordDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/worksheets");
  }

  async optionLoader(inputValue: string, includeArchived: boolean, customRequest?: IRequest): Promise<OptionType[]> {
    return getOptionLoader(
      inputValue,
      ["id", "date"],
      [{ field: "date", order: "ASC" }],
      (request) => this.findAll(customRequest ? merge(request, customRequest) : request),
      null,
      {},
      includeArchived
    );
  }

  setDraft(id: string): Promise<WorksheetDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/set-draft",
        method: "PATCH",
        auth: true,
        body: {},
      })
    );
  }

  setApproved(id: string, comment: string): Promise<WorksheetDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/set-approved",
        method: "PATCH",
        auth: true,
        body: { comment },
      })
    );
  }

  setRejected(id: string, comment: string): Promise<WorksheetDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/set-rejected",
        method: "PATCH",
        auth: true,
        body: { comment },
      })
    );
  }

  setValidated(id: string, setValidatedDto: SetValidatedDto): Promise<WorksheetDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/set-validated",
        method: "PATCH",
        auth: true,
        body: plainToInstance(SetValidatedDto, setValidatedDto),
      })
    );
  }

  setReadyForReview(id: string): Promise<WorksheetDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/set-ready-for-review",
        method: "PATCH",
        auth: true,
        body: {},
      })
    );
  }

  getTaskSummary(id: string): Promise<WorksheetTaskSummaryDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/task-summary",
        method: "GET",
        auth: true,
      })
    );
  }

  moveWorksheet(id: string, projectId: string): Promise<WorksheetDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/move",
        method: "PATCH",
        auth: true,
        body: plainToInstance(MoveWorksheetDto, { projectId: projectId, worksheetId: id }),
      })
    );
  }

  async deleteFromCertification(id: string): Promise<WorksheetDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/delete-certification",
        method: "PATCH",
        auth: true,
        body: {},
      })
    );
  }

  async findLastCertificated(dossierId: string, projectIds: string[]): Promise<FindManyResult<WorksheetDto>> {
    const request: IRequest = {
      filters: {
        "project.dossierId": dossierId,
        projectId: projectIds,
      },
      customAndFilters: [{ field: "certificationId", value: "true", operator: CondOperator.NOT_NULL }],
      join: ["project"],
      rowsPerPage: 1,
      sortBy: "date",
      descending: true,
    };
    return this.findAll(request);
  }

  async getFirstUncertificated(dossierId: string, projectIds: string[]): Promise<FindManyResult<WorksheetDto>> {
    const request: IRequest = {
      filters: { "project.dossierId": dossierId, certificationId: null, projectId: projectIds },
      join: ["project"],
      rowsPerPage: 1,
      sortBy: "date",
    };
    return this.findAll(request);
  }

  async getFirstUncertificatedBetweenDates(
    dossierId: string,
    projectIds: string[],
    startDate: Date | null,
    endDate: Date | null
  ): Promise<FindManyResult<WorksheetDto>> {
    const request: IRequest = {
      filters: {
        "project.dossierId": dossierId,
        certificationId: null,
        status: WorksheetStatusEnum.VALIDATED,
      },
      join: ["project"],
      rowsPerPage: 1,
      sortBy: "date",
    };
    if (startDate) {
      request.filters.fromDate = formatApiDate(startDate);
    }
    if (endDate) {
      request.filters.untilDate = formatApiDate(endDate);
    }

    if (projectIds && projectIds.length) request.filters.projectId = projectIds;

    return this.findAll(request);
  }

  async getWorksheetsWithConflictedTasks(id: string): Promise<WorksheetDto[]> {
    return this.apiHttpClient
      .request<null, WorksheetDto[]>(
        HttpRequest.create({
          url: this.resourceUrl + "/" + id + "/conflicted",
          method: "GET",
          auth: true,
        })
      )
      .then((results) => plainToInstance(WorksheetDto, results));
  }

  async downloadReportExcel(id: string, request: IRequest = {}): Promise<void> {
    await this.downloadExcel(request, "/" + id + "/report-excel", "informe-partes");
  }

  async copy(id: string, body: CopyWorksheetDto): Promise<WorksheetDto> {
    return this.apiHttpClient
      .request(
        HttpRequest.create({
          url: this.resourceUrl + "/" + id + "/copy",
          method: "POST",
          auth: true,
          body: plainToInstance(CopyWorksheetDto, body),
        })
      )
      .then((results) => plainToInstance(WorksheetDto, results));
  }

  async copyUsers(oldId: string, newId: string) {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + oldId + "/copy-users/" + newId,
        method: "POST",
        auth: true,
      })
    );
  }

  async copyUsersMetadata(worksheetId: string) {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + worksheetId + "/metadata",
        method: "POST",
        auth: true,
      })
    );
  }

  async copyTasks(oldId: string, newId: string) {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + oldId + "/copy-tasks/" + newId,
        method: "POST",
        auth: true,
      })
    );
  }

  async copyVehicles(oldId: string, newId: string) {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + oldId + "/copy-vehicles/" + newId,
        method: "POST",
        auth: true,
      })
    );
  }

  async createCopyRecords(oldId: string, newId: string) {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + oldId + "/create-copy-records/" + newId,
        method: "POST",
        auth: true,
      })
    );
  }

  async getMonthlyWorksheetsByUser(userId: string, startDate: string): Promise<WorksheetDto[]> {
    return this.apiHttpClient
      .request<null, WorksheetDto[]>(
        HttpRequest.create({
          url: this.resourceUrl + "/user/" + userId + "/date/" + startDate,
          method: "GET",
          auth: true,
        })
      )
      .then((findManyResult) => {
        return plainToInstance(WorksheetDto, findManyResult);
      });
  }

  async getMyRejectedCount(projectId?: string): Promise<number> {
    return this.apiHttpClient
      .request<null, number>(
        HttpRequest.create({
          url: this.resourceUrl + "/count/rejected" + (projectId ? "/" + projectId : ""),
          method: "GET",
          auth: true,
        })
      )
      .then((result) => Number(result));
  }

  async getSupervisionCount(request: IRequest = {}): Promise<number> {
    return this.apiHttpClient
      .request<null, number>(
        HttpRequest.create({
          url: this.resourceUrl + "/count/supervision",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((result) => Number(result));
  }
}
