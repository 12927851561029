import Notify from 'quasar/src/plugins/notify/Notify.js';;
import { AppError } from "@/core/common/http/errors/app.error";
import { INotification } from "@/interfaces/INotification";

export const useNotify = () => {
  function notify(options: INotification) {
    Notify.create({ ...options, timeout: 2000 });
  }

  function success(text = "¡Éxito!") {
    notify({ type: "positive", message: text });
  }

  function error(text = "Ha ocurrido un error", error?: AppError) {
    notify({ type: "negative", message: text, caption: error?.message ? `INFO: ${error.message}` : undefined });
  }

  function warning(text = "Algo no está bien") {
    notify({ type: "warning", message: text });
  }

  function info(text: string) {
    notify({ type: "info", message: text });
  }

  function createSuccess() {
    success("¡Creación correcta!");
  }

  function createError(e: AppError) {
    error("Ha ocurrido un error al crear.", e);
  }

  function addSuccess() {
    success("¡Añadido!");
  }

  function addError(e: AppError) {
    error("Ha ocurrido un error al añadir.", e);
  }

  function editSuccess() {
    success("¡Edición correcta!");
  }

  function editError(e: AppError) {
    error("Ha ocurrido un error al editar", e);
  }

  function deleteSuccess() {
    success("¡Borrado!");
  }

  function deleteError(e: AppError) {
    error("Ha ocurrido un error al borrar", e);
  }

  function archiveSuccess(isArchived: boolean) {
    success(`¡${isArchived ? "Desarchivado" : "Archivado"} con éxito!`);
  }

  function archiveError(isArchived: boolean, e: AppError) {
    error(`¡Ha ocurrido un error al ${isArchived ? "desarchivar" : "archivar"}`, e);
  }

  return {
    success,
    error,
    warning,
    info,
    createSuccess,
    createError,
    addSuccess,
    addError,
    editSuccess,
    editError,
    deleteSuccess,
    deleteError,
    archiveSuccess,
    archiveError,
  };
};
