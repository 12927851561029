import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { PermissionEnum } from "@altertec_gparn/lib";
import { Type } from "class-transformer";
import { UserDto } from "@/core/features/users/user.dto";

export class ApiTokenDto extends BaseEntityDto {
  name: string;

  permissions: PermissionEnum[];

  isActive: boolean;

  token: string;

  @Type(() => UserDto)
  user: UserDto;
  userId: string;

  toString(): string {
    return this.name;
  }
}
