import { Transform, Type } from "class-transformer";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { CompanyDto } from "@/core/features/companies/company.dto";
import { OperativeUnitDto } from "@/core/features/operative-units/operative-unit.dto";
import { RoleDto } from "@/core/features/roles/role.dto";
import { toEuros } from "@/utils/numberUtils";
import { parseApiDate } from "@/utils/dates/dateFormats";
import { ViewDto } from "@/core/features/views/view.dto";
import { CollectiveAgreementDto } from "@/core/features/collective-agreements/collective-agreement.dto";
import { UserHasVacationDto } from "@/core/features/users-have-vacations/user-has-vacation.dto";
import { endOfYear, isSameYear, startOfYear, subYears } from "date-fns";
import { UserHasQualificationDto } from "@/core/features/users-have-qualifications/user-has-qualification.dto";
import { UserHasNotificationPreferenceDto } from "@/core/features/users-have-notification-preferences/user-has-notification-preference.dto";
import { ApiTokenDto } from "@/core/features/api-tokens/api-token.dto";

export class UserDto extends BaseEntityDto {
  email: string;

  username: string;
  archived: boolean;

  @Type(() => CompanyDto)
  company?: CompanyDto;
  companyId?: string;

  @Type(() => CollectiveAgreementDto)
  collectiveAgreement?: CollectiveAgreementDto;
  collectiveAgreementId?: string;

  @Transform(({ value }) => parseApiDate(value))
  hiredDate: Date;

  @Transform(({ value }) => parseApiDate(value))
  lastEmploymentDate: Date;

  @Type(() => OperativeUnitDto)
  operativeUnit?: OperativeUnitDto;
  operativeUnitId?: string;

  @Transform(({ value }) => parseApiDate(value))
  lastLogin: Date;

  @Transform(({ value }) => parseApiDate(value))
  lastAccess: Date;

  firstName: string;

  lastName: string;

  personalEmail?: string;

  dni?: string;

  @Transform(({ value }) => parseApiDate(value))
  dniExpirationDate?: Date;

  passport?: string;

  @Transform(({ value }) => parseApiDate(value))
  passportExpirationDate?: Date;

  @Transform(({ value }) => parseApiDate(value))
  dateOfBirth?: Date;

  personalPhone1?: string;

  personalPhone2?: string;

  companyPhone1?: string;

  companyPhone2?: string;

  extension?: string;

  @Transform(({ value }) => toEuros(value))
  hourlyRate?: number;

  photoUrl?: string;

  @Type(() => RoleDto)
  role?: RoleDto;

  @Type(() => ViewDto)
  views?: ViewDto[];

  @Type(() => UserHasVacationDto)
  userHasVacations: UserHasVacationDto[];

  @Type(() => UserHasQualificationDto)
  userHasQualifications?: UserHasQualificationDto[];

  isPlannable: boolean;

  @Type(() => OperativeUnitDto)
  accessToOperativeUnits?: OperativeUnitDto[];

  creditCardHolderName?: string;

  idWinda?: string;

  @Type(() => UserHasNotificationPreferenceDto)
  userHasNotificationPreferences?: UserHasNotificationPreferenceDto[];

  @Type(() => ApiTokenDto)
  apiToken?: ApiTokenDto;

  toString(): string {
    return this.firstName && this.lastName ? `${this.firstName} ${this.lastName}` : this.username;
  }

  get newestHiredDate(): Date {
    return this.lastEmploymentDate && this.lastEmploymentDate > this.hiredDate
      ? this.lastEmploymentDate
      : this.hiredDate;
  }

  wasHiredThisYear(fromDate = new Date()): boolean {
    return isSameYear(fromDate, this.newestHiredDate) && this.newestHiredDate > startOfYear(fromDate);
  }

  yearlyEmploymentDate(fromDate: Date = new Date()): Date {
    return this.wasHiredThisYear(fromDate) ? this.newestHiredDate : startOfYear(fromDate);
  }

  getAvailableVacations(fromDate?: Date) {
    if (!this.collectiveAgreementId) return;
    const employmentDate = this.yearlyEmploymentDate(fromDate);
    return this.getAvailableVacationsByYear(employmentDate) + this.getAvailableLastYear(employmentDate);
  }

  getAvailableLastYear(fromDate?: Date) {
    const fromLastYearDate = subYears(fromDate, 1);
    if (this.newestHiredDate > endOfYear(fromLastYearDate)) return 0;

    const lastYearDate = this.yearlyEmploymentDate(fromLastYearDate);
    const lastYearVacations = this.getAvailableVacationsByYear(lastYearDate);

    let accumulatedPrevYear = 0;
    if (lastYearDate.getFullYear() >= 2024) {
      accumulatedPrevYear = this.getAvailableLastYear(lastYearDate);
      return this.getAvailableVacationsWithAccumulatedDaysByYear(lastYearDate, accumulatedPrevYear);
    }
    return lastYearVacations;
  }

  getAvailableVacationsWithAccumulatedDaysByYear(fromDate: Date, lastYearAccumulatedDays: number): number {
    return (
      this.userHasVacations
        .find((userHasVacation) => userHasVacation.year === fromDate.getFullYear())
        ?.availableUsingLastYearAccumulatedDays(fromDate, lastYearAccumulatedDays) ?? 0
    );
  }

  getAvailableVacationsByYear(fromDate?: Date): number {
    return (
      this.userHasVacations
        .find((userHasVacation) => userHasVacation.year === fromDate.getFullYear())
        ?.available(fromDate) ?? 0
    );
  }
}
