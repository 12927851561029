import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { OptionType } from "@/types/OptionType";
import { IRequest } from "@/interfaces/IRequest";
import { ApiTokenDto } from "@/core/features/api-tokens/api-token.dto";
import { CreateApiTokenDto } from "@/core/features/api-tokens/create-api-token.dto";
import { UpdateApiTokenDto } from "@/core/features/api-tokens/update-api-token.dto";
import { HttpRequest } from "@/core/common/http/http-request";

export class ApiTokensResource extends ResourceBaseService<ApiTokenDto, CreateApiTokenDto, UpdateApiTokenDto> {
  protected EntityDto = ApiTokenDto;
  protected CreateEntityDto = CreateApiTokenDto;
  protected UpdateEntityDto = UpdateApiTokenDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/api-tokens");
  }

  async optionLoader(inputValue: string): Promise<OptionType[]> {
    const request: IRequest = inputValue ? { search: inputValue, searchBy: ["id", "name"] } : {};
    request.sortBy = "name";
    const { data } = await this.findAll(request);
    return data.map((value) => value.mapEntityToOptionType([value.name]));
  }

  revoke(id: string): Promise<void> {
    return this.apiHttpClient.request<void, void>(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/revoke",
        auth: true,
        method: "DELETE",
        type: "text",
      })
    );
  }

  reactivate(id: string): Promise<void> {
    return this.apiHttpClient.request<void, void>(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/reactivate",
        auth: true,
        method: "PUT",
        type: "text",
      })
    );
  }
}
