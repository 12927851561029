import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { ExpenseDto } from "./expense.dto";
import { CreateExpenseDto } from "./create-expense.dto";
import { UpdateExpenseDto } from "./update-expense.dto";
import { CreateFileDto } from "../file/create-file.dto";
import { HttpRequest } from "../../common/http/http-request";
import { DeleteFileDto } from "../file/delete-file.dto";
import { ManageExpenseDto } from "@/core/features/expense/manage-expense.dto";
import { plainToInstance } from "class-transformer";

export class ExpensesResource extends ResourceBaseService<ExpenseDto, CreateExpenseDto, UpdateExpenseDto> {
  protected EntityDto = ExpenseDto;
  protected CreateEntityDto = CreateExpenseDto;
  protected UpdateEntityDto = UpdateExpenseDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/expenses");
  }

  createManyAttachments(id: string, files: CreateFileDto[]): Promise<ExpenseDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/attachments",
        method: "POST",
        auth: true,
        body: { bulk: files },
      })
    );
  }

  deleteAttachment(id: string, deleteFile: DeleteFileDto): Promise<ExpenseDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/attachments",
        method: "DELETE",
        auth: true,
        body: deleteFile,
      })
    );
  }

  manage(id: string, manageExpenseDto: ManageExpenseDto): Promise<ExpenseDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/manage",
        method: "PATCH",
        auth: true,
        body: plainToInstance(ManageExpenseDto, manageExpenseDto),
      })
    );
  }

  getByWorksheet(worksheetId: string): Promise<ExpenseDto[]> {
    return this.apiHttpClient
      .request<ExpenseDto, ExpenseDto[]>(
        HttpRequest.create({
          url: this.resourceUrl + "/by-worksheet/" + worksheetId,
          method: "GET",
          auth: true,
        })
      )
      .then((expenses) =>
        plainToInstance(ExpenseDto, expenses, {
          excludeExtraneousValues: false,
        })
      );
  }

  async deleteFromCertification(id: string): Promise<ExpenseDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/delete-certification",
        method: "PATCH",
        auth: true,
        body: {},
      })
    );
  }
}
