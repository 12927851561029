import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { UserHasFavoriteWarehouseDto } from "@/core/features/user-have-favorite-warehouses/user-has-favorite-warehouse.dto";
import { CreateUserHasFavoriteWarehouseDto } from "@/core/features/user-have-favorite-warehouses/create-user-has-favorite-warehouse.dto";

export class UsersHaveFavoriteWarehousesResource extends ResourceBaseService<
  UserHasFavoriteWarehouseDto,
  CreateUserHasFavoriteWarehouseDto,
  null
> {
  protected EntityDto = UserHasFavoriteWarehouseDto;
  protected CreateEntityDto = CreateUserHasFavoriteWarehouseDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/users-have-favorite-warehouses");
  }
}
